@use '../../../styles/variables/colors';

.AccordionTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
}

.AccordionTitle {
  padding: 1rem 1rem 1rem 0;
}

.AccordionContent {
  padding-top: 0.3rem;
  padding-bottom: 1.5rem;
  line-height: 1.5;
  font-weight: 600;
}

.ItemOpenChevron {
  transform: rotate(180deg) translate(0, 2px);
}
.ItemClosedChevron {
  transform: translate(0, 2px);
}

.AccordionItem {
  border-bottom: 1px solid colors.$gray3;
}
.AccordionItem:last-child {
  border-bottom: none;
}
