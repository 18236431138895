@use '../../../../styles/variables/colors';
@import '../../../../styles/breakpoints.module.scss';

.Pharmacy {
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 4rem;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  h3 {
    text-align: center;
    color: colors.$green;
    margin-bottom: 2rem;
    margin-top: 0;
  }

  .pharmacy-info {
    text-align: center;
    margin-bottom: 1.5rem;

    .pharmacy-name {
      margin-top: 4px;
      margin-bottom: 4px;
      font-weight: 700;
    }
  }

  .question-and-radio {
    .question {
      margin-bottom: 1rem;
      text-align: center;
    }
    .choices {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .radio-button {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
  }

  .button-container {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .nav-button {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .new-pharmacy {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .text {
        margin-bottom: 1rem;
        text-align: center;
      }
    }
  }
}
