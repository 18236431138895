@use "./../../../styles/variables/colors" as colors;

.ProgressStep {
  display: flex;
  width: 100%;
  height: 3px;
  background-color: colors.$gray1;

  &Active {
    background-color: colors.$green;
  }
}
