@use '../../../../styles/variables/colors';
@import '../../../../styles/breakpoints.module.scss';
@import '../OnlineAppointment.module.scss';

.StepOneOnlineAppointment {

  .CalendarContainer {
    width: 100%;
  }
  
}
