@use '../../../../styles/variables/colors';
@import '../../../../styles/breakpoints.module.scss';

.CircleWithIcon {
  background-color: colors.$green;
  border-radius: 100%;
  width: 260px;
  height: 260px;
  margin-top: -55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.CircleWithIconSmall {
  background-color: colors.$green;
  border-radius: 100%;
  width: 110px;
  height: 110px;
  margin-top: -55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.CircleDeclined {
  background-color: colors.$orange;
  border-radius: 100%;
  width: 200px;
  height: 200px;
  margin-top: -55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.CalendarDayContainer {
  position: relative;
}
.CalendarDayNumber {
  position: absolute;
  color: colors.$white;
  font-size: 32px;
  top: 19px;
  width: 70px;
  text-align: center;
}

.CircleText {
  color: colors.$white;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
  margin-top: 0.7rem;
  margin-bottom: 0.6rem;
}

.DescriptionSection {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  position: relative;
  align-items: center;
  max-width: 600px;
}
.DescriptionTitle {
  color: colors.$green;
  font-size: 18px;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  margin-bottom: 1rem;
}
.DescriptionTitleOrange {
  color: colors.$orange;
  font-size: 18px;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  margin-bottom: 1rem;
}
.DescriptionText {
  text-align: center;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}
.DescriptionTextSemiBold {
  font-weight: 600;
}
.DescriptionTextSmallMargin {
  margin-bottom: 0.7rem;
}

.ActionButtons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

.Divider {
  display: block;
  position: absolute;
  top: -2.5rem;
  width: 100%;
  height: 1px;
  background-color: colors.$gray1;
}

/* waiting for approval related */
/* --------------------------------------------- */
a {
  &.UploadDocuments {
    display: block;
    margin-top: 0.8rem;
  }
}

/* medication and pharamcy related */
/* --------------------------------------------- */
.MedicationContainer,
.PharmacyContainer {
  flex: 1;
  flex-grow: 1;
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.MedicationPharmacyIcon {
  margin-bottom: 1rem;;
}
.PreparationName,
.PharmacyName {
  font-weight: bold;
  margin-top: 1.5rem;
}
.PreparationDetails {
  margin-top: 0.4rem;
  margin-bottom: 1.8rem;
  line-height: 1.5;
}
.PreparationDetails:last-child {
  margin-bottom: 0;
}
.PharmacyNotice {
  margin-top: 1.4rem;
  margin-bottom: 1.6rem;
  font-style: italic;
  color: colors.$gray0;
}

/* prescription related */
/* --------------------------------------------- */
.PrescriptionContainer {
  flex: 1;
  flex-grow: 1;
  text-align: left;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: colors.$gray3;
}
.PrescriptionContent {
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;

  .DescriptionTitle {
    text-align: left;
    margin-bottom: 0.5rem;
  }

  .PrescriptionHeader {
    display: flex;
    flex-direction: row;

    .PrescriptionTitle {
      flex-grow: 1;
      padding-left: 2rem;

      b {
        font-weight: 600;
        padding-right: 0.2rem;
      }
    }
  }

  .PrescriptionNotice {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    color: colors.$gray0;
  }

  .PreparationContainer {

    .PreparationEntry {
      font-weight: 500;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      border-bottom: 1px solid colors.$gray1;
    }
    .PreparationEntry:last-child {
      border-bottom: none;
    }

  }
}

/* Repsonsive Rules */

@media (min-width: $desktop-min) {
  .MedicationContainer,
  .PharmacyContainer {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media (max-width: $mobile-max) {
  .CircleWithIcon {
    width: 220px;
    height: 220px;
    margin-top: -55px;
    max-width: 80vw;
    max-height: 80vw;
  }
  .CircleWithIconSmall {
    width: 110px;
    height: 110px;
    margin-top: -55px;
    max-width: 110px;
    max-height: 110px;
  }
  .DescriptionText {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .PrescriptionContent {  
    .PrescriptionHeader {  
      svg {
        width: 56px;
      }
      .PrescriptionTitle {
        padding-left: 1.3rem;
      }
    }
  }
}
