.Slider {
  height: 20px;
  /*div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    min-width: 0;
  }*/

  .mark {
    display: none;
  }

  .thumb {
    width: 20px;
    height: 20px;
    background-color: #3db384;
    border-radius: 50%;
    top: calc(50% - 10px);
    outline: none;
  }

  .track {
    height: 4px;
    background-color: #c2c2c2;
    top: calc(50% - 2px);
  }
}
