body {
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  margin: 0;
}

.clickable {
  cursor: pointer;
}

a {
  text-decoration: none;
}

a.underline {
  text-decoration: underline;
}

a.underline:hover {
  text-decoration: none;
}

a.black {
  color: #000000;
}

.text {
  display: inline;
  white-space: pre-wrap;
}

.view {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
  min-width: 0;
}

.relative {
  position: relative;
}

.row {
  flex-direction: row;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.absolute {
  position: absolute;
}

.left {
  left: 0;
  right: auto;
}

.right {
  left: auto;
  right: 0;
}

.bold {
  font-weight: 700;
}

/**
 * Element that is hidden on mobile displays
 */
@media (max-width: 767.98px) {
  .hide-on-mobile {
    display: none;
  }
}
