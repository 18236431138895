@import '../../../styles/breakpoints.module.scss';

.Bar {
  background: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 35px 0 25px;

  .navigation {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > div:first-of-type {
      margin-right: 30px;
    }
  }
}

@media (min-width: $desktop-min) {
  .Bar {
    .hideNavigation {
      display: none;
    }
  }
}
