@use '../../../styles/variables/colors';
@import '../../../styles/breakpoints.module.scss';

.PharmacyCreation {
  .container {
    max-width: 550px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    h3 {
      font-size: 17px;
      font-weight: 700;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    h4 {
      font-size: 16px;
      font-weight: 400;
      margin: 0;
    }

    .header {
      margin-top: 50px;
      margin-bottom: 35px;
      text-align: center;

      h3 {
        color: colors.$green;
      }
    }

    .pharmacy-name {
      margin-top: 15px;
      margin-bottom: 15px;

      .input-and-error {
        margin-top: 10px;
      }
    }

    .contact-fields {
      margin-top: 25px;
      margin-bottom: 15px;

      .fields-grid {
        margin-top: 25px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 25px;
        row-gap: 20px;
      }
    }

    .error {
      margin-top: 5px;
      color: colors.$red;
    }

    .buttons {
      margin-top: 45px;
      margin-bottom: 45px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .back-button {
    cursor: pointer;
    color: colors.$green;
    margin: 20px auto;
  }
}

@media (max-width: $mobile-max) {
  .PharmacyCreation {
    .container {
      .contact-fields {
        .fields-grid {
          .street {
            grid-column-start: span 4;
          }
          .house-number {
            grid-column-start: span 4;
          }
          .postcode {
            grid-column-start: span 4;
          }
          .city {
            grid-column-start: span 4;
          }
          .phone {
            grid-column-start: span 4;
          }
        }
      }
    }
  }
}

@media (max-width: $tablet-max) {
  .PharmacyCreation {
    .container {
      h4 {
        font-size: 14px;
      }
    }
  }
}

@media (min-width: $tablet-min) {
  .PharmacyCreation {
    .container {
      .contact-fields {
        .fields-grid {
          .street {
            grid-column-start: span 3;
          }
          .house-number {
            grid-column-start: span 1;
          }
          .postcode {
            grid-column-start: span 1;
          }
          .city {
            grid-column-start: span 3;
          }
          .phone {
            grid-column-start: span 4;
          }
        }
      }
    }
  }
}
