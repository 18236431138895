@use '../../../styles/variables/colors';

.AppointmentChoice {
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 4rem;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    text-align: center;
    color: colors.$green;
    margin-top: 2rem;
    margin-bottom: 3rem;
    font-weight: 600;
    line-height: 1.5;
  }

  .InfoText {
    margin-bottom: 1rem;
    text-align: center;
  }

  .ButtonContainer {
    margin-top: 1rem;
    margin-bottom: 3.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .button {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}
