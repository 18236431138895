@use '../../../styles/variables/colors';
@import '../../../styles/breakpoints.module.scss';

.Header {
  position: sticky;
  top: 0;
  z-index: 1000;
  margin-left: -2.5rem;
  margin-right: -2.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  background: colors.$white;
}

@media (max-width: $mobile-max) {
  .Header {
    margin-left: -32px;
    margin-right: -32px;
    padding-left: 32px;
    padding-right: 32px;
  }
}
