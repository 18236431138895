@use '../../../styles/variables/colors';
@import '../../../styles/breakpoints.module.scss';

.PharmacyConfirmationModal {
  h3 {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: colors.$green;
    margin-bottom: 10px;
  }

  .question {
    display: block;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .pharmacy-name {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .pharmacy-address {
    text-align: center;
  }
}
