.InputLoader {
  width: 20px;
  height: 20px;
  min-width: 20px !important;
  min-height: 20px !important;
  border-radius: 50%;
  border: 3px solid;
  border-right: 3px solid transparent !important;
  margin-right: 5px;
  background: transparent;
  animation: spin 500ms linear infinite;
}

@keyframes spin {
  100% { transform: rotate(360deg); }
}
