@use '../../../styles/variables/colors';

.ContractCheckbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .Text {
    .Link {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
