@use '../../../styles/variables/colors';
@import '../../../styles/breakpoints.module.scss';

.delete-modal {
  .filename {
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.DocumentBox {
  cursor: pointer;
  background: colors.$gray3;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5px;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .thumb {
    flex-shrink: 0;
  }

  .filename {
    flex-grow: 1;
    font-size: 12px;
    font-weight: 700;
    overflow-wrap: anywhere;
    margin-left: 15px;
  }

  .icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    margin-right: 10px;
  }
}
