@use '../../../styles/variables/colors';

.TermsAndConditions {
  
  .text {
    * {
      font-family: 'Montserrat-Regular', sans-serif !important;
    }
  }
}
