@use '../../../styles/variables/colors';
@import '../../../styles/breakpoints.module.scss';

.Calendar {
  width: 100%;

  .AbsenceInfo {
    margin-bottom: 2rem;

    font-size: 0.8rem;

    .AbsenceInfoHeader {
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5rem;

      svg {
        width: 20px;
        height: 20px;
      }

      h4 {
        margin: 0;
        margin-top: 3px;
        margin-left: 0.5rem;
        font-weight: 600;
        color: colors.$orange;
      }
    }

    .AbsenceInfoDescription {
      color: colors.$gray0;
      line-height: 1.5;
    }
  }

  .month-year {
    padding-top: 15px;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .change-month {
      display: flex;
      flex-grow: 1;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .change-month-icon {
          &.decrease {
            transform: rotate(90deg);
          }

          &.increase {
            transform: rotate(-90deg);
          }
      }
    }

    .text {
      margin-left: 10px;
      margin-right: 10px;
      font-weight: 700;
      color: colors.$black;
    }
  }

  .week-days {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .week-day-container {
      width: calc(100% / 7);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-bottom: 12px;

      .week-day {
        color: colors.$gray0;
      }
    }
  }

  .days-rows {
    width: 100%;

    .day-container {
      width: calc(100% / 7);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .days-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 0.5rem;
    }
  }
}
