@import '../../../styles/variables/colors';

.ProgressBar {
  width: 100%;
  height: 3px;
  overflow: hidden;
  display: flex;

  div {
    margin-right: 6px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.StepLabel {
  color: $gray5;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
