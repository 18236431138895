@use '../../../../styles/variables/colors';
@import '../../../../styles/breakpoints.module.scss';
@import '../OnlineFirstAppointment.module.scss';

.StepOneOnlineFirstAppointment {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;

  .CalendarContainer {
    width: 100%;
  }
}
