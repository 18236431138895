@use '../../../../styles/variables/colors';
@import '../../../../styles/breakpoints.module.scss';
@import '../OfflineAppointment.module.scss';

.StepOneOfflineAppointment {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;

  .CalendarContainer {
    width: 100%;
  }

  .BackButton {
    margin-top: 1.5rem;
  }
}
