.Label {
  display: inline !important;
  width: 100%;
}

@media (min-width: 480px) and (max-width: 767.98px) {
  .Label {
    width: auto;
  }
}
