@font-face {
  font-family: Montserrat-Regular;
  src: url('./../assets/fonts/Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: Montserrat-Regular;
  src: url('./../assets/fonts/Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}
@font-face {
  font-family: Montserrat-Regular;
  src: url('./../assets/fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: Montserrat-Regular;
  src: url('./../assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: Montserrat-Regular;
  src: url('./../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: Montserrat-Regular;
  src: url('./../assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
}
