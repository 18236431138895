@use '../../../styles/variables/colors';
@import '../../../styles/breakpoints.module.scss';

.CalendarDay {
  cursor: pointer;
  width: calc(100% / 7);
  height: 2.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .day {
    display: flex;
    padding: 9px 9px;
    margin: 3px 3px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    color: colors.$black;
    align-items: center;
    justify-content: center;
  }

  .passed-day {
    color: colors.$gray5;
  }

  .has-slot {
    border: 1.75px solid colors.$green;
  }

  .doctor-absent {
    border: 1.75px solid colors.$orange;
  }

  .is-selected {
    background: colors.$green;
    color: colors.$white;
  }

  .is-selected-doctor-absent {
    background: colors.$orange;
    color: colors.$white;
  }
}
