@use '../../../../styles/variables/colors';
@import '../../../../styles/breakpoints.module.scss';
@import '../OnlineAppointment.module.scss';

.StepTwoOnlineAppointment {

  .SelectedDateContainer {
    width: 100%;
    text-align: left;
    padding: 1rem;
    margin-bottom: 2rem;
    background-color: colors.$gray3;

    .SelectedDate {
      margin-bottom: 0.7rem;
      b {
        font-weight: 600;
        white-space: nowrap;
      }
    }
  }

  .SlotsContainer {
    width: 100%;
    margin-bottom: 3rem;
    .Slot {
      height: 3rem;

      display: flex;
      flex-direction: row;
      align-items: center;

      border-bottom: 1px solid colors.$gray3;
      transition: height 200ms linear;

      &.Selected {
        height: 4rem;
        background: colors.$green;
        color: colors.$white;

        .SlotText {
          padding-left: 0.7rem;
        }
      }

      .SlotText {
        padding-left: 0.3rem;
        flex-grow: 1;
      }
      .SlotButton {
        background-color: colors.$white;
        color: colors.$green;
        margin-right: 0.5rem;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
