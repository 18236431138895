@use '../../../../styles/variables/colors';
@import '../../../../styles/breakpoints.module.scss';
@import '../OnlineAppointment.module.scss';

.StepThreeOnlineAppointment {
  .AppointmentInfoContainer {
    margin-bottom: 2.5rem;

    .AppointmentInfoLabel {
      margin-bottom: 0.3rem;
      text-align: center;
    }

    .AppointmentInfoDescription {
      font-weight: 600;
      text-align: center;
    }
  }

  .CouponCodeField {
    span {
      display: inline-block;
      width: 70%;
    }

    input {
      width: 50%;
    }

  }
  .BackButton {
    margin-top: 1.5rem;
  }
}
