@use '../../../styles/variables/colors';
@import '../../../styles/breakpoints.module.scss';

.DocumentPreview {
  z-index: 5000;
  background: colors.$white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  .title-bar {
    padding: 25px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .close {
      cursor: pointer;
      transform: rotate(90deg);
    }

    .filename {
      margin-left: 20px;
    }

    .share {
      cursor: pointer;
      margin-left: auto;
    }
  }

  .img-area {
    flex-grow: 1;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  .pdf-area {
    flex-grow: 1;
    overflow-y: hidden;

    .pdf-doc {
      width: 100%;
      height: 100%;

      .pdf-page {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        canvas {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
