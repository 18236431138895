@use '../../../styles/variables/colors';
@import '../../../styles/breakpoints.module.scss';

.NavigationDrawer {
  position: fixed;
  width: 320px;
  left: -320px;
  right: auto;
  top: 0;
  bottom: auto;
  background: colors.$white;
  transition: left ease 200ms;
  overflow-y: auto;
  z-index: 2000;

  .header {
    margin-top: 20px;
    padding: 8px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .spacer {
    height: 48px;
  }

  .item {
    cursor: pointer;
    box-sizing: border-box;
    padding: 12px;
    height: 56px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:hover {
      background: #e5e5e5;
    }

    > div {
      padding: 0 12px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      > div {
        margin-left: 12px;
      }
    }
  }

  .divider {
    height: 1px;
    background: colors.$gray1;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .close {
    cursor: pointer;
  }

  &.open {
    left: 0;
    box-shadow: rgba(0, 0, 0, 0.29) 0px 3px 4.65px;
  }
}


@media (min-width: $desktop-min) {
  .NavigationDrawer {
    width: 320px;
    left: 0px;
    border-right: 1px solid colors.$gray4;
  }
  .close {
    display: none;
  }
}
