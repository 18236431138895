@use '../../../../styles/variables/colors';
@import '../../../../styles/breakpoints.module.scss';
@import '../OfflineAppointment.module.scss';

.StepTwoOfflineAppointment {
  .SelectedDateContainer {
    width: 100%;
    text-align: left;
    padding: 1rem;
    margin-bottom: 2rem;
    background-color: colors.$gray3;

    .SelectedDate {
      margin-bottom: 0.7rem;
      b {
        font-weight: 600;
        white-space: nowrap;
      }
    }
  }

  .SlotsContainer {
    width: 100%;
    margin-bottom: 3rem;
    .Slot {
      height: 4.5rem;
      overflow: hidden;

      display: flex;
      flex-direction: row;
      align-items: center;

      border-bottom: 1px solid colors.$gray3;
      transition: height 200ms linear;

      &.Selected {
        height: 10rem;
        background: colors.$green;
        color: colors.$white;

        margin-left: -2.5rem;
        margin-right: -2.5rem;

        padding-left: 2.5rem;
        padding-right: 2.5rem;

        .SlotDoctor {
          margin-bottom: 1rem;
        }
        @media (max-width: $mobile-max) {
          margin-left: -32px;
          margin-right: -32px;

          padding-left: 32px;
          padding-right: 32px;
        }
      }

      .SlotText {
        padding-left: 0.3rem;
        font-weight: 600;
        flex-grow: 1;
      }
      .SlotDoctor {
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
        font-weight: 400;

        svg {
          margin-right: 0.5rem;;
        }
      }
      .SlotButton {
        background-color: colors.$white;
        color: colors.$green;
        margin-right: 0.5rem;
        margin-top: 0.5rem;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .slots {
    .selected {
      cursor: pointer;
      background: colors.$green;
      color: colors.$white;
    }
    .not-selected {
      cursor: pointer;
    }
  }
}
