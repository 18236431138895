@use '../../../../styles/variables/colors';
@import '../../../../styles/breakpoints.module.scss';
@import '../OfflineFirstAppointment.module.scss';

.StepOneOfflineFirstAppointment {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;

  .Location {

    margin-bottom: 4rem;
    text-align: center;

    .LocationName {
      font-weight: 600;
      margin-bottom: 1rem;
      font-size: 1.1rem;
    }

    .LocationDetailsWithIcon {

      display: inline-flex ;
      flex-direction: row;
      align-items: top;

      margin-bottom: 0.8rem;

      svg {
        flex-shrink: 0;
        margin-right: 1rem;
        margin-top: 0.2rem;
      }

    }

    .locationChooseButton {
      margin-top: 1.5rem;
    }
  }
}
