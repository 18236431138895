.twilio-video {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	margin: 0;
	padding: 0;

	&.twilio-video-portrait {
		object-fit: contain;
	}
}

.twilio-participant {
	display: block;
	position: relative;
	border-radius: 1rem;
	overflow: hidden;
	background-color: #000;
	margin: 0;
	padding: 0;

	figcaption {
		font-size: 0.75rem;
		position: absolute;
		left: 0.25rem;
		bottom: 0.25rem;
		padding: 0.25rem 0.5rem;
		background: #00000066;
		color: "#fff";
		border-radius: 1rem;
		overflow: hidden;
		margin: 0;
	}

	/* Local is pinned to the bottom right like Facetime. */
	&.twilio-participant-local {
		position: absolute;
		right: 1.5rem;
		bottom: 1.5rem;
		bottom: calc(1.5rem + env(safe-area-inset-bottom));
		box-shadow: 0 0 0.5rem #00000066;

		figcaption {
			right: 0.25rem;
			left: auto;
		}

		.twilio-video {
			&.twilio-video-mirror {
				transform: scaleX(-1),
			}

			&.twilio-video-landscape {
				width: 20vw;
				height: 15vw;
				min-width: 20vh;
				min-height: 15vh;
				object-fit: cover;
			}

			&.twilio-video-portrait {
				width: 15vw;
				height: 20vw;
				min-width: 15vh;
				min-height: 20vh;
				object-fit: cover;
			}
		}
	}
}

.twilio-room {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	display: grid;
	gap: 2rem;
	padding: 2rem;
	font-size: 1rem;
	grid-auto-flow: column dense;
	text-align: center;
}