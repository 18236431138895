@use '../../../styles/variables/colors';
@import '../../../styles/breakpoints.module.scss';

.CurrentStepContainer {
  background-color: colors.$gray3;
  margin-top: calc(55px + 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FaqSectionContainer {
  display: flex;
  width: 100%;
}
.FaqSection {
  margin-top: 3rem;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5rem;
}
.MedicationPharmacyInfo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 3rem;
}
.MedicationPharmacyInfo > div:nth-child(2) {
  border-left: 1px solid colors.$gray1;
}

@media (max-width: $desktop-min) {
  .MedicationPharmacyInfo {
    flex-direction: column;
    margin-top: 0;
  }
  .MedicationPharmacyInfo > div:nth-child(2) {
    border-left: 0;
    border-top: 1px solid colors.$gray1;
  }

}

@media (max-width: $mobile-max) {
  .CurrentStepContainer {
    margin-top: calc(55px + 40px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
