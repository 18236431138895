.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 288px;
}

.first-label {
  width: 90%;
}

.input-container {
  display: flex;
  flex-direction: column;
}

.input-field {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.error-delete {
  font-size: 12px;
}

.error-unverified {
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .container {
    width: 550px;
    max-width: none;
  }
}

@media (min-width: 1280px) {
  .first-label {
    width: auto;
  }

  .input-container {
    width: auto;
  }

  .error-delete {
    font-size: unset;
  }
}
