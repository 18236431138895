@use '../../../styles/variables/colors';
@import '../../../styles/breakpoints.module.scss';
@import './OfflineFirstAppointment.module.scss';

.OfflineFirstAppointmentSuccess {
  .AppointmentSuccessContainer {
    margin-bottom: 2.5rem;
    line-height: 1.5;
    text-align: center;

    .AppointmentSuccessMain {
      font-weight: 600;
    }
    .AppointmentSuccessInfo {
      font-size: 0.8rem;
    }
  }
}
