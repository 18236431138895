@use '../../../../styles/variables/colors';
@import '../../../../styles/breakpoints.module.scss';

.StandardPharmacy {
  border: 1px solid colors.$gray2;
  max-width: 450px;
  width: 100%;
  background: colors.$white;
  padding: 5px;
  margin: 5px auto;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5px;

  .headline {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: colors.$green;

    .title-left {
      font-size: 20px;
      margin-right: 10px;
    }

    .title-right {
      font-size: 14px;
    }
  }
}

.Details {
  width: calc(100% - 8px);
  padding: 5px;

  .button {
    align-items: center;
    padding: 8px 20px;
    margin: 10px;
    border-radius: 25px;
    width: fit-content;
    overflow: hidden;

    .text {
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0.25px;
    }
  }

  .button-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
  }

  .details-button {
    margin-left: 0;
    background-color: colors.$green;

    .text {
      color: colors.$white;
    }
  }

  .pharmacy-button {
    margin-right: 0;
    background-color: colors.$white;
    border: 1px solid colors.$green;

    .text {
      color: colors.$green;
    }
  }
}
