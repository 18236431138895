@use '../../../styles/variables/colors';
@import '../../../styles/breakpoints.module.scss';

.PharmacyList {
  margin: 55px 0;
  align-items: stretch;

  svg {
    align-self: center;
  }

  .info-text {
    max-width: 450px;
    align-items: center;
    margin: 50px auto 20px;
    text-align: center;

    h3 {
      font-size: 19px;
      color: colors.$green;
      font-weight: 700;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    h4 {
      font-size: 16px;
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .more-pharmacies-link {
    cursor: pointer;
    display: inline-block;
    color: colors.$green;
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
    margin: 20px auto;
    max-width: 450px;
  }

  .pharmacy-creation-button {
    margin: 30px auto;
  }

  .back-button {
    cursor: pointer;
    color: colors.$green;
    margin: 20px auto;
  }
}

@media (max-width: $mobile-max) {
  .bigger-width {
    margin-left: -27px;
    margin-right: -27px;
  }
}

@media (max-width: $phablet-max) {
  .more-pharmacies-link {
    font-size: 14px;
  }
}
