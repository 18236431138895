.ModalContent {
  * {
    font-family: 'Montserrat-Regular', sans-serif !important;
  }
}

.ModalHeadline {
  font-size: 1.75em;
}

.RegisterProcedure {
  div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    min-width: 0;
  }

  .container {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
  }
  
  .row {
    flex-direction: row;
  }
  
  .box {
    width: 99%;
  }
  
  .shadow {
    box-shadow: 5px 5px 3px 40px rgba(0, 0, 0, 0.08);
  }

  .save-button-container {
    position: relative;
  }

  .Agreement {
    display: inline;
    cursor: pointer;
    text-decoration: underline;
  }
}

@media (max-width: 767.98px) {
  .RegisterProcedure {
    .phone-container {
      margin-top: 10px;
    }

    .button-container {
      align-items: center;
    }

    .button-web-container {
      align-items: center;
    }

    .name-container {
      flex-direction: column;
    }

    .save-button-container {
      justify-content: flex-start;
    }
  }
}

@media (max-width: 330px) {
  .save-button-container {
    padding-bottom: 75px !important;
  }
}

@media (min-width: 768px) {
  .RegisterProcedure {
    .container {
      width: 550px;
    }

    .name-container {
      flex-direction: row;
      margin-bottom: 10px;
    }

    .email-container {
      margin-top: 10px;
      margin-bottom: 10px;
    }    
  }
}

@media (min-width: 1280px) {
  .RegisterProcedure {
    .container {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 550px;
    }

    .disease-box-container {
      flex-direction: row;
      justify-content: space-between;
    }

    .row {
      flex-direction: row;
    }

    .box {
      width: 49%;
    }

    .first-headline {
      margin-top: 50px;
    }

    .email-container {
      margin-right: 10px;
      flex: 2 1 0%;
    }

    .name-container {
      margin-top: 10px;
    }

    .phone-container {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 10px;
      flex: 2 1 0%;

      input {
        margin-bottom: 10px;
      }
    }

    .password-container {
      margin-right: 10px;
      flex: 2 1 0%;

      .password-error {
        max-width: 265px;
      }
    }

    .password-repeat-container {
      margin-left: 10px;
      flex: 2 1 0%;
    }
  }
}
