@use '../../../styles/variables/colors';
@import '../../../styles/breakpoints.module.scss';

.ForgotPassword {
  display: flex;
  flex-direction: column;

  .container {
    width: 100%;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;

    .info {
      margin-bottom: 20px;
    }

    .email-field {
      margin-bottom: 45px;

      .error {
        margin-top: 4px;
        color: colors.$red;
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .back-button {
        cursor: pointer;
        color: colors.$green;
        margin: 20px auto;
      }
    }

    .success {
      color: colors.$green;
      margin-bottom: 4px;
    }
  }
}
