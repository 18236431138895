@for $i from 1 through 100 {
  .mt-#{$i} {
    margin-top: #{$i}rem;
  }

  .mb-#{$i} {
    margin-bottom: #{$i}rem;
  }

  .mr-#{$i} {
    margin-right: #{$i}rem;
  }

  .ml-#{$i} {
    margin-left: #{$i}rem;
  }
}
