.UserHeader {
  .menu-header {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      display: block;
    }
  }

  .menu {
    flex-direction: column;
    box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px;

    .menu-item {
      cursor: pointer;
      padding: 16px 20px;
    }
  }
}
