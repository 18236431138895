@use './variables/colors';
@import './breakpoints.module.scss';

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  line-height: 1.5;
}

a {
  color: colors.$green;
  &:hover {
    text-decoration: underline;
  }
}

.container {
  height: 100%;
  min-height: 100vh;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.fullWidthSectionInContainer {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

@media (max-width: $mobile-max) {
  .container {
    padding-left: 32px;
    padding-right: 32px;
  }
  .fullWidthSectionInContainer {
    margin-left: -32px;
    margin-right: -32px;
    padding-left: 32px;
    padding-right: 32px;
  }
  a {
    text-decoration: underline;
  }
}
@media (min-width: $desktop-min) {
  .container:has(.Header) {
    margin-left: 320px;
  }
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-center {
  justify-content: center;
}

h1 {
  font-size: 1.5em;
  font-weight: 700;
}

h2 {
  font-size: 1.25em;
  font-weight: 700;
}

h3 {
  font-size: 1.17em;
  font-weight: 700;
}

h4, h5, h6 {
  font-size: 1em;
  font-weight: 700;
}
