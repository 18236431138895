@use '../../../styles/variables/colors';

.RadioButton {
  flex-shrink: 0;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  width: 25px;
  height: 25px;
  background: colors.$gray6;
  border: 1px solid colors.$gray6;
  border-radius: 50%;
  margin: 0 10px 0 0;
  align-self: flex-start;

  &:checked {
    background: colors.$green;
    border-width: 5px;
  }
}

.Label {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
