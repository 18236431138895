@use '../../../styles/variables/colors';
@import '../../../styles/breakpoints.module.scss';

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  z-index: 2000;
}

.Modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  max-width: 720px;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  background: colors.$white;
  border-radius: 4px;
  padding: 35px;

  .Content {
    flex-grow: 1;
    overflow-y: auto;
  }

  .button-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
}

@media (max-width: $tablet-max) {
  .Modal {
    .button-container {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
