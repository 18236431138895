.loading-dot {
  animation-name: pulsate;
  animation-duration: 750ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
}

.loading-dot-1 {
  animation-delay: 0;
}

.loading-dot-2 {
  animation-delay: 250ms;
}

.loading-dot-3 {
  animation-delay: 500ms;
}

@keyframes pulsate {
  from {
    opacity: 0.4;
    transform: scale(1.0);
  }
  to {
    opacity: 1;
    transform: scale(1.2);
  }
}
