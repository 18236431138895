@use '../../../../styles/variables/colors';
@import '../../../../styles/breakpoints.module.scss';

.PremiumPharmacy {
  border: 4px solid colors.$green;
  max-width: 450px;
  width: 100%;
  background: colors.$green;
  align-items: center;
  text-align: center;
  padding: 5px;
  margin: 5px auto;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5px;

  .image {
    margin-top: 15px;
    margin-bottom: 15px;
    flex: 1;
  }

  .rating-row {
    position: relative;
    flex-direction: row;
    justify-content: space-between;
	  align-items: center;
    color: colors.$gray2;
    width: 100%;
	  padding: 0 0 0 0.5rem;
  }

  .premium-check {
    color: colors.$gray2;
    display: flex;
  }

  .details-line {
    border-top-color: colors.$gray2;
    border-top-width: 1px;
    border-top-style: solid;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.Details {
  width: 100%;

  .description {
    margin: 20px 10px;
    font-size: 15px;
    line-height: 30px;
    color: colors.$white;
    border-bottom-color: colors.$gray1;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding-bottom: 15px;
    margin-bottom: 5px;
  }

  .divider {
    border-bottom-color: colors.$gray1;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding: 15px 5px;
    border-radius: 0;
    margin-bottom: 10px;
    width: auto;

  }

  .button {
    align-items: center;
    background: transparent;
    padding: 8px 20px;
    border-radius: 25px;
    border: 1px solid colors.$white;
    margin-bottom: 5px;

    .button-text {
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0.25px;
      color: colors.$white;
    }
  }

  .button-choose-pharmacy {
    align-self: unset;
    background: colors.$white;
    margin-left: 10px;
    margin-right: 0;
    width: fit-content;
    overflow: hidden;

    .button-text {
      color: colors.$green;
    }
  }

  .button-details {
    margin-left: 0;
    margin-right: 10px;
  }
}

@media (max-width: $tablet-max) {
  .Details {
    .button {
      align-self: center;
    }
  }
}

@media (min-width: $phablet-min) {
  .Details {
    .button {
      width: fit-content;
    }
  }
}

@media (max-width: $mobile-max) {
  .Details {
    .button-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (max-width: 360px) {
  .PremiumPharmacy {
    .premium-check {
      font-size: 12px;
      margin-top: 2px;
    }
  }
}
