.UpdatePersonalData .input-row:has(.error-text) {
  margin-bottom: 35px !important;
}

.UpdatePersonalData .second-input-row:has(.error-text) {
  margin-bottom: 35px !important;
}

@media (max-width: 479.98px) {
  .UpdatePersonalData .input-row:has(.error-text) {
    margin-bottom: 35px !important;
  }
}

@media (max-width: 332.98px) {
  .UpdatePersonalData .second-input-row:has(.error-text) {
    margin-bottom: 56px !important;
  }
}

.UpdatePersonalData .error-text {
  top: calc(100% + 10px);
  bottom: auto !important;
}

@media (min-width: 1280px) {
  .UpdatePersonalData .input-row {
    max-width: 550px;
  }

  .UpdatePersonalData .second-input-row {
    width: 550px;
  }
}
