$white: #ffffff;
$black: #000000;
$white-opaque: rgba(255, 255, 255, 0.25);
$green: #3db384;
$green-opaque: rgba(61, 179, 132, 0.25);
$black: #000;
$gray0: #777;
$gray1: #e0e0e0;
$gray2: #e3e3e3;
$gray3: #f0f0f0;
$gray4: #f8f8f8;
$gray5: #cccccc;
$gray6: #efefee;
$gray7: #979797;
$red: #ff0000;
$red2: #dc0000;
$orange: orange;
