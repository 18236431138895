.ProgressBarContainer {
  max-width: 550px;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
}

.RadioContainer {
  column-gap: 3rem;
}

.ErrorText {
  color: #f00;
}
