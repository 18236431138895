@use '../../../../styles/variables/colors';
@import '../../../../styles/breakpoints.module.scss';

.PsychologicalSideEffects {
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  h3 {
    text-align: center;
    color: colors.$green;
    margin-bottom: 2rem;
    margin-top: 0;
  }

  .info-text {
    text-align: center;
    margin-bottom: 3rem;
  }

  .checkboxes {
    width: 100%;
    background: colors.$gray4;
    padding: 2.5rem 2rem 0;

    .side-effects-checkbox {
      margin-bottom: 1.5rem;
    }

    .last-checkbox {
      margin-bottom: 0;
    }

    .separator {
      margin-top: 1.5rem;
      width: 100%;
      height: 1px;
      background: colors.$gray6;
    }    
  }

  .no-side-effects {
    width: 100%;
    padding: 1.5rem 2rem;
    background: colors.$gray4;
  }

  .button-container {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 5rem;

    > div {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}

@media (max-width: $mobile-max) {
  .PsychologicalSideEffects {
    .checkboxes {
      width: calc(100% + 64px);
      margin-left: -32px;
      margin-right: -32px;
    }

    .no-side-effects {
      width: calc(100% + 64px);
      margin-left: -32px;
      margin-right: -32px;
    }
  }
}
